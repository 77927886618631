<template>
  <div class="instructor-page" v-if="user">
    <div class="container container--big">
      <div class="instructor-page__main">
        <base-card class="instructor-page__card instructor-page__main-card instructor-page__main-card--user">
          <user-card
            class="instructor-page__user"
            :firstName="user.firstName"
            :lastName="user.lastName"
            :avatar="user.avatarId"
            :slug="user.slug"
          />

          <base-button
            @click="copyToClipboard()"
            class="instructor-page__user-button"
          >
            {{ $t('instructor.share') }}
            <img
              class="instructor-page__user-icon"
              alt
              src="/assets/icons/share.svg"
            />
          </base-button>
        </base-card>

        <base-card
          :title="$t('instructor.statistics')"
          class="instructor-page__main-card instructor-page__main-card--stats"
        >
          <ul class="instructor-page__stats">
            <li class="instructor-page__stats-item">
              <img
                class="instructor-page__stats-icon"
                alt
                src="/assets/icons/likes.svg"
              />

              <p class="instructor-page__stats-value">
                {{ user.totalLikes }}
              </p>

              <p class="instructor-page__stats-info">
                {{ $t('instructor.liked') }}
              </p>
            </li>

            <li class="instructor-page__stats-item">
              <img
                class="instructor-page__stats-icon"
                alt
                src="/assets/icons/courses.svg"
              />

              <p class="instructor-page__stats-value">
                {{ user.totalCourses }}
              </p>

              <p class="instructor-page__stats-info">
                {{ $t('instructor.added') }}
              </p>
            </li>
          </ul>
        </base-card>

        <base-card
          :title="$t('editProfile.portfolio.heading')"
          class="instructor-page__portfolio instructor-page__main-card instructor-page__main-card--portfolio"
        >
          <ul class="instructor-page__portfolio-list">
            <li
              class="instructor-page__portfolio-item"
              v-for="(image, index) in portfolioImages"
              :key="`image-${index}`"
            >
              <a
                class="instructor-page__portfolio-link"
                href="#"
                @click="openLightbox(index)"
              >
                <base-image
                  class="instructor-page__portfolio-image"
                  :src="image"
                />
              </a>
            </li>
          </ul>
        </base-card>

        <base-card
          :title="$t('instructor.about')"
          class="instructor-page__card instructor-page__main-card instructor-page__main-card--description"
        >
          <p class="instructor-page__card-description">
            {{ user.bio }}
          </p>

          <ul class="instructor-page__socials">
            <li
              class="instructor-page__socials-item"
              v-for="(socialItem, index) in socialMedia"
              :key="`social-item-${index}`"
            >
              <a
                class="instructor-page__socials-link"
                :href="socialItem.link"
                :title="socialItem.name"
                target="blank"
              >
                <img
                  class="instructor-page__socials-icon"
                  alt
                  :src="`/assets/socials/${socialItem.name}.svg`"
                />
              </a>
            </li>
          </ul>

          <p class="instructor-page__join">
            {{ $t('Date of join') }} : {{ joinDate }}
          </p>
        </base-card>

        <base-card
          :title="$t('instructor.courses')"
          class="instructor-page__card instructor-page__main-card instructor-page__main-card--courses"
        >
          <ul class="instructor-page__courses" v-if="userCourses.length">
            <course-tile
              v-for="course in userCourses"
              :key="course.id"
              :course="course"
              class="instructor-page__courses-tile"
              :class="{ 'courses__tile--animate': course.id }"
            />
          </ul>

          <base-button
            v-if="userCourses.length"
            class="instructor-page__courses-button"
            @click="loadMoreCourses()"
            :title="$t('instructor.loadMore')"
            :disabled="isButtonDisabled"
            :is-loading="isLoading"
          >
            {{ $t('instructor.loadMore') }}
          </base-button>

          <p class="instructor-page__courses-text" v-if="!userCourses.length">
            {{ $t('instructor.emptyCourses') }}
          </p>

          <p class="instructor-page__courses-no-more" v-if="isButtonDisabled">
            {{ $t('instructor.noCourses') }}
          </p>
        </base-card>
      </div>
    </div>

    <!-- Lightbox -->
    <vue-easy-lightbox
      :visible="isLightboxVisible"
      :imgs="portfolioImages"
      :index="activeLightboxIndex"
      moveDisabled
      @hide="closeLightbox"
    />
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox';
import auth from '@/api/services/auth';
import course from '@/api/services/course';
import { getImageLink } from '@/helpers';
import dayjs from 'dayjs';

export default {
  name: 'InstructorPage',
  components: {
    VueEasyLightbox,
  },
  data: () => ({
    isLightboxVisible: false,
    activeLightboxIndex: 0,
    user: null,
    userCourses: [],
    paginationOffset: 3,
    paginationPage: 0,
    paginationlimit: 3,
    isLoading: false,
    isButtonDisabled: false,
  }),
  methods: {
    copyToClipboard() {
      const element = document.createElement('textarea');
      element.value = window.location.href;

      document.body.appendChild(element);
      element.select();

      document.execCommand('copy');
      document.body.removeChild(element);

      this.$store.dispatch('notification/spawn', {
        title: this.$t('accountSettings.changePassword.success.title'),
        text: this.$t('instructor.copy'),
        type: 'success',
      });
    },
    openLightbox(index) {
      this.activeLightboxIndex = index;
      this.isLightboxVisible = true;
    },
    closeLightbox() {
      this.isLightboxVisible = false;
      this.activeLightboxIndex = 0;
    },
    async loadMoreCourses() {
      this.isLoading = true;

      try {
        const { data } = await course.getCourses({
          skip: this.paginationPage,
          limit: this.paginationlimit,
          author_id: this.user.id,
        });

        this.userCourses = [...this.userCourses, ...data];
        this.paginationPage += this.paginationlimit;

        if (!data.length) this.isButtonDisabled = true;
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          title: this.$t('apiHandler.error.title'),
          text: this.$t('apiHandler.error.text'),
          type: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    portfolioImages() {
      if (!this.user.featuredImagesIds) return [];

      return this.user.featuredImagesIds.map((imageID) => {
        return getImageLink(imageID);
      });
    },
    socialMedia() {
      return this.unfilteredSocialMedia.filter((social) => social.isActive);
    },
    unfilteredSocialMedia() {
      if (!this.user) return [];

      return [
        {
          name: 'Pinterest',
          link: this.user.pinterestLink,
          isActive: !!this.user.pinterestLink,
        },
        {
          name: 'Facebook',
          link: this.user.facebookLink,
          isActive: !!this.user.facebookLink,
        },
        {
          name: 'Instagram',
          link: this.user.instagramLink,
          isActive: !!this.user.instagramLink,
        },
        {
          name: 'Youtube',
          link: this.user.youtubeLink,
          isActive: !!this.user.youtubeLink,
        },
        {
          name: 'Twitter',
          link: this.user.twitterLink,
          isActive: !!this.user.twitterLink,
        },
        {
          name: 'Linkedin',
          link: this.user.linkedInLink,
          isActive: !!this.user.linkedInLink,
        },
      ];
    },
    joinDate() {
      return dayjs(this.user.joinDate).format('DD MMMM YYYY');
    },
  },
  async created() {
    this.$store.dispatch('ui/setPreloader', true);

    try {
      const authorDetails = await auth.getAuthorDetails(this.$route.params.slug);
      const authorCourses = await course.getCourses({
        skip: this.paginationPage,
        limit: this.paginationlimit,
        author_id: authorDetails.data.id,
      });

      this.paginationPage += this.paginationlimit;
      this.user = authorDetails.data;
      this.userCourses = authorCourses.data;
    } catch (err) {
      this.$store.dispatch('notification/spawn', {
        title: this.$t('apiHandler.error.title'),
        text: this.$t('apiHandler.error.text'),
        type: 'error',
      });

      this.$router.push('/404');
    } finally {
      this.$store.dispatch('ui/setPreloader', false);
    }
  },
};
</script>

<style lang="scss" src="./Instructor.scss" />
